<template>
  <div class="forestry">
    <img class="forestry-img" src="../../../assets/images/product/forestry.png" alt="">
    <div class="forestry-text">
      <h4 class="forestry-title">智慧林业</h4>
      <div class="forestry-title-border"></div>
      <p>
        针对大规模森林场景存在的监管难度大、经济效益低和信息反馈满等情况，提出包含林业碳汇、林下经济和智慧林区的方案，包含林业碳汇方面的地图基本操作、林区基本情况概述、碳汇计量评估和碳汇经营分析；林下经济的自动化小浆果智能采摘设备、配合小浆果等森林作物实现的饮料工厂设计咨询业务；针对智慧林区提供的包含GIS地图、林区情况实时监管等功能的智慧林业监管平台。
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.forestry {
  padding-top: 80px;
  min-height: 100vh;
  position: relative;
}

.forestry-img {
  width: 49vw;
  height: 42vh;
  min-height: 541px;
  position: absolute;
  top: 13.6%;
  left: 0;
  z-index: 1;
}

.forestry-text {
  width: 57.4vw;
  height: 47.9vh;
  min-height: 607px;
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  top: 13.6%;
  left: 32.9%;
  text-align: left;
  padding-left: 19%;
  .forestry-title {
  font-size: 40px;
  font-weight: 500;
  color: #323232;
  margin-top: 94px;
}

.forestry-title-border {
  width: 75px;
  height: 2px;
  background: #d00001;
  margin: 11px 0 39px;
}
  p{
    width: 80%;
    color: #808080;
    font-size: 20px;
    line-height: 2;
    text-align: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }
}


</style>