<template>
  <div class="computer">
    <img class="computer-img" src="../../../assets/images/product/computer.png" alt="">
    <div class="computer-text">
      <h4 class="computer-title">智慧商业</h4>
      <div class="computer-title-border"></div>
      <p>
        随着互联网、物联网、云计算、大数据、移动终端技术的快速深度融合发展，商业日益变得智慧、高效和便捷。智慧商业的实质是以信息技术为支撑，创新人类商业模式，拓宽商业流程管理手段，提高社会运转整体效能。通过人工智能技术、语音识别技术、5G通信技术等，实现市场信息的高速分类与处理，增加商业信息判断依据，提升整体业务流程效率。
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.computer {
  padding-top: 80px;
  min-height: 100vh;
  position: relative;
}

.computer-img {
  width: 49vw;
  height: 42vh;
  min-height: 541px;
  position: absolute;
  top: 13.6%;
  left: 0;
  z-index: 1;
}

.computer-text {
  width: 57.4vw;
  height: 47.9vh;
  min-height: 607px;
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  top: 13.6%;
  left: 32.9%;
  text-align: left;
  padding-left: 19%;
  .computer-title {
  font-size: 40px;
  font-weight: 500;
  color: #323232;
  margin-top: 94px;
}

.computer-title-border {
  width: 75px;
  height: 2px;
  background: #d00001;
  margin: 11px 0 39px;
}
  p{
    width: 80%;
    color: #808080;
    font-size: 20px;
    line-height: 2;
    text-align: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }
}


</style>