<template>
  <div class="sxj">
    <img class="sxj-img" src="../../../assets/images/product/sxj.png" alt="">
    <div class="sxj-text">
      <h4 class="sxj-title">数字电商</h4>
      <div class="sxj-title-border"></div>
      <p>
        由于互联网和信息技术的快速发展，全新的数字电商发展模式开始崭露头角。得益于其低成本与高效性，电子商务成为现代商务贸易中的重要交流方式。更有价值的是，电子商务具有高度的开放性，成为促进多边贸易交流的重要手段，数字电商是利用互联网、物联网、无线通信等通信技术和数据分析手段将商务的流程、渠道、营销、运营等流程数字化、互联网化、智能化。同普通的电子商务不同的是，它不仅仅是将现代信息技术和商务的结合，而且注重将数据的价值应用到商业中去。在信息爆炸的，数字信息取代了大部分实体交易，在很大程度上充当了贸易经济产物，直接代表了经济利益。发展数字电商既是经济发展的需要，也是沟通协作的要求。
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.sxj {
  padding-top: 80px;
  min-height: 100vh;
  position: relative;
}

.sxj-img {
  width: 49.4%;
  height: 38.8%;
  min-height: 541px;
  position: absolute;
  top: 13.6%;
  left: 0;
  z-index: 1;
}

.sxj-text {
  width: 57.4%;
  height: 54.3%;
  min-height: 607px;
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  top: 13.6%;
  left: 32.9%;
  text-align: left;
  padding-left: 19%;
  .sxj-title {
  font-size: 40px;
  font-weight: 500;
  color: #323232;
  margin-top: 94px;
}

.sxj-title-border {
  width: 75px;
  height: 2px;
  background: #d00001;
  margin: 11px 0 39px;
}
  p{
    width: 80%;
    height: 62%;
    overflow: hidden;
    overflow-y: scroll;
    color: #808080;
    font-size: 20px;
    line-height: 2;
    text-align: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }
}


</style>