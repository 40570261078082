<template>
  <div class="water">
    <img class="water-img" src="../../../assets/images/product/water.png" alt="">
    <div class="water-text">
      <h4 class="water-title">智慧水利</h4>
      <div class="water-title-border"></div>
      <p>
        针对各类河、湖泊的监管流程与监控模式，现阶段多数情况下还是通过手动记录、导入等操作方案录入电脑，仅仅实现了水利信息的数据化，尚未能形成有规模的数据库分析模式。我司通过各类传感器、摄像头等设备，结合大数据、数据分析、可视化平台技术，实现水利信息的综合研判，强化信息的准确性与时效性，优化分析水利信息流程，建立新的水利业务管理模式。
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.water {
  padding-top: 80px;
  min-height: 100vh;
  position: relative;
}

.water-img {
  width: 49vw;
  height: 42vh;
  min-height: 541px;
  position: absolute;
  top: 13.6%;
  left: 0;
  z-index: 1;
}

.water-text {
  width: 57.4vw;
  height: 47.9vh;
  min-height: 607px;
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  top: 13.6%;
  left: 32.9%;
  text-align: left;
  padding-left: 19%;
  .water-title {
  font-size: 40px;
  font-weight: 500;
  color: #323232;
  margin-top: 94px;
}

.water-title-border {
  width: 75px;
  height: 2px;
  background: #d00001;
  margin: 11px 0 39px;
}
  p{
    width: 80%;
    color: #808080;
    font-size: 20px;
    line-height: 2;
    text-align: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }
}


</style>