<template>
  <div class="farm">
    <img class="farm-img" src="../../../assets/images/product/farm.png" alt="">
    <div class="farm-text">
      <h4 class="farm-title">智慧农业</h4>
      <div class="farm-title-border"></div>
      <p>
        基于黑龙江地方产业特色，以及农田、养殖场等实际场景需求，四福科技提出种养种植一体化行业设计方案，提供包含基于农业的微生物技术土壤养护方案、作物需求资源定向补充方案、禽畜膳食纤维饲料制成方案等，基于畜牧业的农舍环境除臭方案、小分子酵素饮用水方案、复合有机肥制成方案等，从多个维度保障农业与畜牧业的种养种植收益，提升龙江整体行业竞争力，实现农业、畜牧业的有机结合。
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.farm {
  padding-top: 80px;
  min-height: 100vh;
  position: relative;
}

.farm-img {
  width: 49vw;
  height: 42vh;
  min-height: 541px;
  position: absolute;
  top: 13.6%;
  left: 0;
  z-index: 1;
}

.farm-text {
  width: 57.4vw;
  height: 63.6%;
  min-height: 607px;
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  top: 13.6%;
  left: 32.9%;
  text-align: left;
  padding-left: 19%;
  .farm-title {
  font-size: 40px;
  font-weight: 500;
  color: #323232;
  margin-top: 94px;
}

.farm-title-border {
  width: 75px;
  height: 2px;
  background: #d00001;
  margin: 11px 0 39px;
}
  p{
    width: 80%;
    color: #808080;
    font-size: 20px;
    line-height: 2;
    text-align: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }
}


</style>