<template>
  <div class="code">
    <img class="code-img" src="../../../assets/images/product/code.png" alt="">
    <div class="code-text">
      <h4 class="code-title">职业教育</h4>
      <div class="code-title-border"></div>
      <p>
        爱扣钉隶属于四福科技，是黑龙江省专业的IT培训机构，致力于培养高端的IT人才，主要开展Web前端和Java后端等方向的培训，我们以完善的课程体系，全新的软件项目，强大的师资团队，雄厚的公司背景作为坚强的后盾，成为进入大型互联网公司的加油站。爱扣钉紧随科技的步伐，不断的更迭创新，打造覆盖 IT 全产业链的职业课程版图，不断更新课程，实时挖掘企业需要人才类型，做到培训出来的学员技术能够即刻上岗操作，实战式教学更是爱扣钉一直以来的教学理念之一，理论与实践相结合才是教学的重中之重。爱扣钉不仅仅包括课程教学，而是从招生、学员服务、在线教学、学员与老师实时沟通互动到学习平台的保障，学员就业技巧的传递，以及推荐就业在内的整套服务体系的融合并重，一体化的学员管理、全流程的品保服务，解决了学员课程从学习到就业的一系列难题。
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.code {
  padding-top: 80px;
  min-height: 100vh;
  position: relative;
}

.code-img {
  width: 49vw;
  height: 42vh;
  min-height: 541px;
  position: absolute;
  top: 13.6%;
  left: 0;
  z-index: 1;
}

.code-text {
  width: 57.4vw;
  height: 54.3%;
  // max-height: 51.9vh;
  min-height: 607px;
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  top: 13.6%;
  left: 32.9%;
  text-align: left;
  padding-left: 19%;
  .code-title {
  font-size: 40px;
  font-weight: 500;
  color: #323232;
  margin-top: 94px;
}

.code-title-border {
  width: 75px;
  height: 2px;
  background: #d00001;
  margin: 11px 0 39px;
}
  p{
    width: 80%;
    height: 62%;
    overflow: hidden;
    overflow-y: scroll;
    color: #808080;
    font-size: 20px;
    line-height: 2;
    text-align: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }
}


</style>