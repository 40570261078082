<template>
  <div class="product">
    <!-- 导航栏 -->
    <Nav :isHome="false"></Nav>
    <section :class="['product-container',innerHeight * 1 < 789?'product-footer-bottom':'']">
      <Farm v-if="type == 'farm'"></Farm>
      <Forestry  v-if="type == 'forestry'"></Forestry>
      <Water v-if="type == 'water'"></Water>
      <Animal v-if="type == 'animal'"></Animal>
      <Computer v-if="type == 'computer'"></Computer>
      <Code v-if="type == 'code'"></Code>
      <Sxj v-if="type == 'sxj'"></Sxj>
    </section>
    <!-- 底部 -->
    <Footer :class="innerHeight * 1 > 1268?'product-footer':''"></Footer>
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
import Footer from '@/components/footer.vue'
import Farm from './components/farm.vue'
import Forestry from './components/forestry.vue'
import Water from './components/water.vue'
import Animal from './components/animal.vue'
import Computer from './components/computer.vue'
import Code from './components/code.vue'
import Sxj from './components/sxj.vue'

export default {
  data() {
    return {
      innerHeight:null,
      type:''
    }
  },
  components: {
    Nav,
    Footer,
    Farm,
    Forestry,
    Water,
    Animal,
    Computer,
    Code,
    Sxj
  },
  created(){
    this.innerHeight = window.innerHeight
    window.scrollTo(0,0)
    this.type = this.$route.query.type
  },
  beforeDestroy(){
    window.scrollTo(0,0)
  }
 
}
</script>

<style lang="scss" scoped>
.product {
  width: 100vw;
  min-height: 100vh;
  background-image: url('../../assets/images/product/product.png');
  background-size: 100% 100%;
  position: relative;
}
.product-container{
  height: 63.6%;
}
.product-footer-bottom{
  margin-bottom: 105px;
}

.product-footer {
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>